<template>
  <div
    class="bg-[#EDF4FF] rounded-md relative px-6 py-9 flex justify-between mb-5">
    <div class="flex items-start justify-between w-full">
      <div class="flex flex-col gap-1.5">
        <div class="flex items-center gap-1">
          <span class="font-simplerRegular text-sm text-secondary-800">
            {{ $t('survey') }}
          </span>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <RouterLink
            :to="{
              name: 'survey-progress',
              params: {
                group: route.params.group,
                course: route.params.survey,
              },
            }">
            <span
              class="font-simplerBold text-[24px] text-secondary-900 hover:underline">
              {{ currentSurvey?.caption }}
            </span>
          </RouterLink>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <div class="flex items-center gap-1 text-[24px] -mt-1">
            <span class="font-simplerBold text-secondary-800">דאשבורד</span>
          </div>
        </div>
        <div class="flex items-center gap-1 text-md -mt-1">
          <span class="font-simplerBold text-secondary-800">
            {{ currentGroup?.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-2">
      <div class="h-10 w-96">
        <BaseCarousel
          :current-index="selectedStudent"
          :items="students"
          :slide="false"
          @set-current-item="changeStudent">
          <template #default="item">
            <div class="flex items-center justify-start gap-5 h-10">
              <img class="h-8 w-8" :src="item?.avatar" alt="" />
              <h2>{{ item?.fullName }}</h2>
            </div>
          </template>
        </BaseCarousel>
      </div>
      <div class="flex items-center justify-end">
        <BaseButton
          v-if="questions.length"
          outline
          color="blue"
          @click="
            $router.push({
              name: 'survey-progress-visual',
              params: {
                group: route.params.group,
                survey: route.params.survey,
              },
              query: {
                student: student?.id,
              },
            })
          ">
          דאשבורד ויזואלי {{ '>' }}
        </BaseButton>
      </div>
      <BaseTabs
        v-if="areasList.length"
        v-model="activeTab"
        color="default"
        variant="underline"
        class="py-5">
        <BaseTab v-for="tab in tabsTypes" :key="tab" :name="tab" :title="tab">
          <BaseTabs
            v-model="activeSurvey"
            color="blue"
            variant="underline"
            class="py-5">
            <BaseTab
              v-for="survey in selectedList"
              :key="survey.id"
              :name="survey.id"
              :title="survey.title">
              <BaseTable>
                <TableBody>
                  <TableRow
                    v-for="ques in questions"
                    :key="ques"
                    :title="
                      ques.title.content.blocks[0].data.text.replace(
                        /(<([^>]+)>)/gi,
                        '',
                      )
                    ">
                    <TableCell>
                      <div
                        class="w-full flex items-center justify-between h-18 px-5 py-4">
                        <p>{{ getSubmission(ques) }}</p>
                        <BaseBadge
                          pill
                          :type="
                            getBadgeTheme(activeTab === tabsTypes.AREAS, ques)
                          ">
                          {{
                            getBadgeText(activeTab === tabsTypes.AREAS, ques)
                          }}
                        </BaseBadge>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow v-if="!questions.length">
                    <TableCell>
                      <div
                        class="w-full flex items-center justify-between h-18 px-5 py-4">
                        שאלון זה עוד לא נענה
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </BaseTable>
            </BaseTab>
          </BaseTabs>
        </BaseTab>
      </BaseTabs>
      <div
        v-else
        class="bg-white w-full flex items-center justify-between h-18 px-5 py-4 mt-5">
        <p>שאלון זה עוד לא נענה</p>
      </div>
    </div>
  </div>
  <SideWidget>
    <BaseWidget
      v-for="question in openQuestion"
      :key="question.id"
      :container="false">
      <template #title>
        <div class="text-md font-simplerBold">
          השיתוף של {{ student?.fullName }} ב{{ question.questionnaire.title }}
        </div>
      </template>
      <template #default>
        <div class="p-5">
          <p class="text-secondary-600 text-sm mb-2">
            {{ formatDate(new Date(question.submitted_at), true) }}
          </p>
          <BaseTextArea
            v-model="submissions[question.question.id]"
            disabled></BaseTextArea>
        </div>
      </template>
    </BaseWidget>
  </SideWidget>
</template>
<script setup lang="ts">
import {
  BaseCarousel,
  BaseButton,
  BaseWidget,
  BaseTextArea,
  BaseTable,
  TableBody,
  TableRow,
  TableCell,
  BaseTabs,
  BaseTab,
  BaseBadge,
} from '@nwire/amit-design-system';
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IStudent } from '/@/app/models/interfaces';
import { collect } from 'collect.js';
import { useSurvey } from '/@/app/composables/useSurvey';
import { useSurveyStore } from '/@/app/store/surveys';
import { formatDate } from '@/utils/helpers';
import { useAccountStore } from '/@/app/store/account';

const route = useRoute();
const router = useRouter();
const accountStore = useAccountStore();
const surveyStore = useSurveyStore();
const selectedStudent = ref(-1);
const currentGroup = computed(() =>
  accountStore.groups.find(group => group.id === route.params.group),
);
const currentSurvey = computed(() =>
  collect(currentGroup.value.surveys).where('id', route.params.survey).first(),
);
const student = ref<IStudent>();
const students = computed<IStudent[]>(() => currentGroup.value?.students ?? []);
const {
  studentId,
  submissions,
  needsList,
  areasList,
  getAreaQuestion,
  getNeedQuestion,
  getOpenQuestion,
  getSubmission,
  getBadgeTheme,
  getBadgeText,
} = useSurvey();

const routeStudent = computed(() => route.query.student);
watch([routeStudent, students], () => {
  getSelectedStudent();
});
const getSelectedStudent = () => {
  if (!students.value.length) return;
  selectedStudent.value = students.value.findIndex(
    student => student.id === route.query.student,
  );
  selectedStudent.value =
    selectedStudent.value > -1 ? selectedStudent.value : 0;
  student.value = students.value[selectedStudent.value];
  studentId.value = student.value.id;
};
onMounted(() => {
  surveyStore.init({
    survey: route.params.survey as string,
    group: route.params.group,
  });
  getSelectedStudent();
});

const tabsTypes = {
  AREAS: 'זירות',
  NEEDS: 'צרכים',
};

const activeTab = ref(tabsTypes.AREAS);

const selectedList = computed(() =>
  activeTab.value === tabsTypes.AREAS ? areasList.value : needsList.value,
);
const activeSurvey = ref(
  selectedList.value.length ? selectedList.value[0]?.id : -1,
);
watch(selectedList, () => {
  activeSurvey.value = selectedList.value.length
    ? selectedList.value[0]?.id
    : -1;
});

const questionsByArea = computed(() => getAreaQuestion(activeSurvey.value));
const questionsByNeed = computed(() => getNeedQuestion(activeSurvey.value));
const questions = computed(() =>
  activeTab.value === tabsTypes.AREAS
    ? questionsByArea.value
    : questionsByNeed.value,
);
const openQuestion = computed(() => getOpenQuestion());

const changeStudent = val => {
  selectedStudent.value = val;
};

watchEffect(() => {
  if (students.value.length)
    router.push({
      name: 'survey-dashboard',
      params: {
        group: route.params.group,
        survey: route.params.survey,
      },
      query: {
        student: students.value[selectedStudent.value]?.id,
      },
    });
  //
});
</script>
