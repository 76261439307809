<template>
  <div class="relative h-full">
    <!-- Carousel wrapper -->
    <div class="overflow-hidden relative h-fit">
      <!-- Item 1 -->
      <!-- duration-700 ease-in-out-->
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="index === currentItem ? 'z-30' : 'hidden z-0'"
        class="inset-0 -translate-y-0 px-5">
        <div class="block w-full px-10">
          <slot v-bind="item">
            <img
              :alt="item?.alt"
              :src="item?.src"
              class="block absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2"/>
          </slot>
        </div>
      </div>
    </div>
    <!-- Slider indicators -->
    <div
      v-if="!noIndicators"
      class="flex absolute bottom-5 left-1/2 z-30 space-x-3 -translate-x-1/2">
      <button
        v-for="(_, index) in items"
        :key="index"
        :aria-label="'Slide ' + index"
        :class="index === currentItem ? 'bg-white' : 'bg-white/50'"
        aria-current="false"
        class="w-3 h-3 rounded-full"
        type="button"
        @click.prevent="slideTo(index)"/>
    </div>
    <!-- Slider controls -->
    <template v-if="!noControls">
      <button
        class="flex absolute top-0 left-0 z-30 justify-center items-center pr-4 h-full cursor-pointer group focus:outline-none"
        data-carousel-prev
        type="button"
        @click.prevent="previousItem">
        <span
          class="inline-flex border border-secondary-500 justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg
            class="w-5 h-5 text-secondary-500 sm:w-6 sm:h-6 dark:text-gray-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 19l-7-7 7-7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"/>
          </svg>
          <span class="hidden">Previous</span>
        </span>
      </button>
      <button
        class="flex absolute top-0 right-0 z-30 justify-center items-center pl-4 h-full cursor-pointer group focus:outline-none"
        data-carousel-next
        type="button"
        @click.prevent="nextItem">
        <span
          class="inline-flex border border-secondary-500 justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg
            class="w-5 h-5 text-secondary-500 sm:w-6 sm:h-6 dark:text-gray-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 5l7 7-7 7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"/>
          </svg>
          <span class="hidden">Next</span>
        </span>
      </button>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watchEffect } from 'vue';
import type { Item } from '@/components/Carousel/types';
import type { PropType } from 'vue';

const emits = defineEmits(['set-current-item']);
const props = defineProps({
  items: {
    type: Array as PropType<Item[]>,
    default() {
      return [];
    },
  },
  currentIndex: {
    type: Number,
    default: 0,
  },
  noIndicators: {
    type: Boolean,
    default: true,
  },
  noControls: {
    type: Boolean,
    default: false,
  },
  slide: {
    type: Boolean,
    default: false,
  },
  slideInterval: {
    type: Number,
    default: 3000,
  },
  animation: {
    type: Boolean,
    default: false,
  },
});

const currentItem = ref(props.currentIndex);
const direction = ref('');
const interval = ref();

const automaticSlide = () => {
  interval.value = setInterval(function () {
    nextItem();
  }, props.slideInterval);
};

const resetInterval = () => {
  clearInterval(interval.value);
  automaticSlide();
};

const slideTo = (index: number) => {
  currentItem.value = index;
  resetInterval();
};

const nextItem = () => {
  if (currentItem.value !== props.items.length - 1) {
    currentItem.value++;
  } else {
    currentItem.value = 0;
  }
  direction.value = 'right';
  emits('set-current-item', currentItem.value);
};

const previousItem = () => {
  if (currentItem.value !== 0) {
    currentItem.value--;
  } else {
    currentItem.value = props.items.length - 1;
  }
  direction.value = 'left';
  emits('set-current-item', currentItem.value);
};

onMounted(() => {
  if (props.slide) {
    automaticSlide();
  }
});

watchEffect(() => {
  currentItem.value = props.currentIndex;
});
</script>
